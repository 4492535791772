import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react'
import Observer from '@researchgate/react-intersection-observer'
// import { use100vh } from 'react-div-100vh'
import { useScrollDirection } from 'react-use-scroll-direction'
import { useWindowSize } from 'react-use'
import clsx from 'clsx/lite'

import Image from '../image'

import Logo from '../logo'

import './header.css'

import NavigationDesktop from '../navigation/navigation-desktop'
import NavigationMobile from '../navigation/navigation-mobile'

import { useFormatSupportLineBreaks } from '../../hooks/formatText'

const backgroundImageStyle = '!absolute !inset-0 w-full h-full'

/**
 * Renders the header including the page menu.
 *
 * @example
 * <Header
 * headline="The default header"
 * description="The description below the headline"
 * backgroundImageId="randomPictureId"
 * />
 * @example
 * <Header
 * headline="The transparent version"
 * description="The description below the headline"
 * backgroundImageId="randomPictureId"
 * transparent
 * />
 */
const Header = ({
  headline,
  description,
  backgroundImageId,
  transparent = false,
}: {
  headline?: string
  description?: string | React.ReactElement
  backgroundImageId?: string
  transparent?: boolean
}) => {
  const [headerPassed, setHeaderPassed] = useState(false)
  const [headerVisibleAgain, setHeaderVisibleAgain] = useState(false)
  const headerBarRef = useRef(null)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [headerHeightScrollTop, setHeaderHeightScrollTop] = useState(140)
  const { scrollDirection } = useScrollDirection()
  const { width } = useWindowSize()

  const [isScrollingUp, setIsScrollingUp] = useState(false)

  useEffect(() => {
    if (!scrollDirection) {
      return
    }
    setIsScrollingUp(scrollDirection === 'UP')
  }, [scrollDirection])

  const handleHeaderIntersection = useCallback(
    (event) => {
      setHeaderPassed(!event.isIntersecting)
      setHeaderVisibleAgain(headerPassed && event.isIntersecting)
      setHeaderHeight((v) => (v === 0 ? headerBarRef.current.offsetHeight : v))
    },
    [headerPassed]
  )

  useEffect(() => {
    headerBarRef.current &&
      width &&
      setHeaderHeightScrollTop(headerBarRef.current.offsetHeight)
  }, [headerBarRef, width])

  // const realViewportHeight = use100vh()
  // const [bestViewportHeight, setBestViewportHeight] = useState(0)
  // useEffect(() => {
  //   if (!bestViewportHeight) {
  //     setBestViewportHeight(Math.floor(realViewportHeight * 0.7))
  //   }
  // }, [bestViewportHeight, setBestViewportHeight, realViewportHeight])

  const hasBackgroundMedia = useMemo(
    () => !!backgroundImageId,
    [backgroundImageId]
  )
  // const mainHeight = useMemo(
  //   () => (hasBackgroundMedia ? bestViewportHeight : null),
  //   [hasBackgroundMedia, bestViewportHeight]
  // )

  // Can't be transparent when no image is given
  const actualTransparent = useMemo(
    () => (!hasBackgroundMedia ? false : transparent),
    [hasBackgroundMedia, transparent]
  )

  // Set the CSS variable on the root element
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--floating-header-height',
      `${headerHeight + 24}px`
    )
  }, [headerHeight])

  const renderedDescription = useMemo(() => {
    if (typeof description === 'string') {
      return description.replace(/\s*<br[\s/]*>\s*/g, '\n')
    }
    return description
  }, [description])

  const formattedHeadline = useFormatSupportLineBreaks(headline)

  return (
    <Observer onChange={handleHeaderIntersection}>
      <div className="relative mb-content-gap">
        <div
          // height={mainHeight}
          className={clsx(
            'header-main',
            hasBackgroundMedia && 'has-background-media'
          )}
        >
          <div style={{ height: headerHeightScrollTop }}>
            <div
              className={clsx(
                'header-bar',
                transparent && 'transparent',
                headerPassed ? 'passed' : 'not-passed',
                headerVisibleAgain && 'visible-again'
              )}
              ref={headerBarRef}
            >
              <NavigationDesktop
                logo={<Logo />}
                headerPassed={headerPassed}
                transparent={actualTransparent}
                isScrollingUp={isScrollingUp}
              />
              <NavigationMobile
                logo={<Logo />}
                headerVisibleAgain={headerVisibleAgain}
              />
            </div>
          </div>
          {hasBackgroundMedia && headline && (
            <>
              <div className="relative flex-1">
                <div className="relative z-20 h-full max-w-3xl mx-auto text-center whitespace-pre-wrap pointer-events-none">
                  <h1 className="header-content-top">{formattedHeadline}</h1>
                </div>
                {!transparent && hasBackgroundMedia && (
                  <div
                    className={clsx(
                      'header-background-media-wrapper',
                      actualTransparent && 'transparent'
                    )}
                  >
                    <Image
                      className={clsx(
                        backgroundImageStyle,
                        'landscape:!block portrait:!hidden'
                      )}
                      id={backgroundImageId}
                      contextKey="landscape"
                      fit="cover"
                      loading="eager"
                      fetchpriority="high"
                    />
                    <Image
                      className={clsx(
                        backgroundImageStyle,
                        'portrait:!block landscape:!hidden'
                      )}
                      id={backgroundImageId}
                      contextKey="portrait"
                      fit="cover"
                      loading="eager"
                      fetchpriority="high"
                    />
                  </div>
                )}
              </div>
              {transparent && hasBackgroundMedia && (
                <div
                  className={clsx(
                    'header-background-media-wrapper',
                    actualTransparent && 'transparent'
                  )}
                >
                  <Image
                    className={clsx(
                      backgroundImageStyle,
                      'landscape:!block portrait:!hidden'
                    )}
                    id={backgroundImageId}
                    contextKey="landscape"
                    fit="cover"
                    loading="eager"
                    fetchpriority="high"
                  />
                  <Image
                    className={clsx(
                      backgroundImageStyle,
                      'portrait:!block landscape:!hidden'
                    )}
                    id={backgroundImageId}
                    contextKey="portrait"
                    fit="cover"
                    loading="eager"
                    fetchpriority="high"
                  />
                </div>
              )}
            </>
          )}
        </div>
        {hasBackgroundMedia && (
          <div className="relative z-20 h-full max-w-3xl mx-auto text-center whitespace-pre-wrap">
            <div
              className="border-solid border-8 border-white border-t-0 mx-4
                          bg-gray-100
                          p-4 pt-0 md:p-8 md:pt-2
                          font-body font-normal italic text-gray-700 text-base
                          shadow-sm"
            >
              {renderedDescription}
            </div>
          </div>
        )}
      </div>
    </Observer>
  )
}

export default Header
