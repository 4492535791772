import React from 'react'

const Logo = () => (
  <img
    width="222"
    height="40"
    style={{ width: '100%' }}
    src="/stanford-ackel-logo.svg"
    alt="Stanford & Ackel - Logo"
  />
)

export default Logo
